import { isDefined, getJwtToken } from '../utils';
import { handleMockRequest } from '../mocks/server-handlers';
import { QueryParams, RequestParams } from '../types';

const DEFAULT_RETRY_COUNT = 10;

export const buildUrl = (
  path: string,
  baseUrl: string,
  params?: QueryParams,
): string => {
  const url = `${baseUrl}${path}`;
  if (!isDefined(params)) {
    return url;
  }
  const queryString = Object.entries(params)
    .filter(([, value]) => isDefined(value) && value !== '')
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        const encodedArray = value
          .map((item) => encodeURIComponent(String(item)))
          .join(',');

        return `${key}=${encodedArray}`;
      } else {
        return `${key}=${encodeURIComponent(String(value))}`;
      }
    })
    .join('&');

  if (queryString === '') {
    return url;
  } else {
    return `${url}?${queryString}`;
  }
};

/**
 * A wrapper around fetch API.
 */
class Api {
  static getDefaultHeaders(): HeadersInit {
    const token = getJwtToken();
    if (token === null) {
      throw new Error('Not authenticated!');
    }

    return {
      Authorization: token,
    };
  }

  async get<T>(params: RequestParams): Promise<T> {
    const isTestEnv = process.env.NODE_ENV === 'test';
    const {
      mock,
      path,
      queryParams,
      config,
      baseUrl,
      retry = DEFAULT_RETRY_COUNT,
    } = params;

    if (mock || isTestEnv) {
      return handleMockRequest({ ...params, method: 'GET' });
    }

    const urlWithQuery = buildUrl(path, baseUrl, queryParams);
    let retryCount = 0;
    while (retryCount < retry) {
      try {
        return await fetch(urlWithQuery, {
          ...config,
          headers: {
            'Content-Type': 'application/json',
            ...Api.getDefaultHeaders(),
            ...config?.headers,
          },
        }).then((res) => res.json());
      } catch (e) {
        retryCount++;
      }
    }
    throw new Error('Request timed out!');
  }

  async post<T>(params: RequestParams): Promise<T> {
    const isTestEnv = process.env.NODE_ENV === 'test';
    const {
      mock,
      path,
      queryParams,
      body,
      config,
      baseUrl,
      retry = DEFAULT_RETRY_COUNT,
    } = params;

    if (mock || isTestEnv) {
      return handleMockRequest({ ...params, method: 'POST' });
    }

    const urlWithQuery = buildUrl(path, baseUrl, queryParams);
    let retryCount = 0;
    while (retryCount < retry) {
      try {
        return await fetch(urlWithQuery, {
          ...config,
          ...(isDefined(body) ? { body: transformBody(body) } : {}),
          method: 'POST',
          headers: {
            ...Api.getDefaultHeaders(),
            ...config?.headers,
          },
        }).then((res) => res.json());
      } catch (e) {
        retryCount++;
      }
    }
    throw new Error('Request timed out!');
  }

  async put<T>(params: RequestParams): Promise<T> {
    const isTestEnv = process.env.NODE_ENV === 'test';
    const {
      mock,
      path,
      queryParams,
      body,
      config,
      baseUrl,
      retry = DEFAULT_RETRY_COUNT,
    } = params;

    if (mock || isTestEnv) {
      return handleMockRequest({ ...params, method: 'PUT' });
    }

    const urlWithQuery = buildUrl(path, baseUrl, queryParams);
    let retryCount = 0;
    while (retryCount < retry) {
      try {
        return await fetch(urlWithQuery, {
          ...config,
          ...(isDefined(body) ? { body: transformBody(body) } : {}),
          method: 'PUT',
          headers: {
            ...Api.getDefaultHeaders(),
            ...config?.headers,
          },
        }).then((res) => res.json());
      } catch (e) {
        retryCount++;
      }
    }
    throw new Error('Request timed out!');
  }

  async patch<T>(params: RequestParams): Promise<T> {
    const isTestEnv = process.env.NODE_ENV === 'test';
    const {
      mock,
      path,
      queryParams,
      body,
      config,
      baseUrl,
      retry = DEFAULT_RETRY_COUNT,
    } = params;

    if (mock || isTestEnv) {
      return handleMockRequest({ ...params, method: 'PATCH' });
    }

    const urlWithQuery = buildUrl(path, baseUrl, queryParams);
    let retryCount = 0;
    while (retryCount < retry) {
      try {
        return await fetch(urlWithQuery, {
          ...config,
          ...(isDefined(body) ? { body: transformBody(body) } : {}),
          method: 'PATCH',
          headers: {
            ...Api.getDefaultHeaders(),
            ...config?.headers,
          },
        }).then((res) => res.json());
      } catch (e) {
        retryCount++;
      }
    }
    throw new Error('Request timed out!');
  }

  async delete<T>(params: RequestParams): Promise<T> {
    const isTestEnv = process.env.NODE_ENV === 'test';
    const {
      mock,
      path,
      queryParams,
      config,
      baseUrl,
      retry = DEFAULT_RETRY_COUNT,
    } = params;

    if (mock || isTestEnv) {
      return handleMockRequest({ ...params, method: 'DELETE' });
    }

    const urlWithQuery = buildUrl(path, baseUrl, queryParams);
    let retryCount = 0;
    while (retryCount < retry) {
      try {
        return await fetch(urlWithQuery, {
          ...config,
          method: 'DELETE',
          headers: {
            ...Api.getDefaultHeaders(),
            ...config?.headers,
          },
        }).then((res) => res.json());
      } catch (e) {
        retryCount++;
      }
    }
    throw new Error('Request timed out!');
  }
}

const transformBody = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: Record<string, any>,
): string | FormData | null => {
  if (body instanceof FormData) {
    return body;
  }

  return JSON.stringify(body);
};

export default new Api();
