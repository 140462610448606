import React, { useMemo } from 'react';

import { SessionProps } from '../../../../../../types';
import { knowledgeBaseDefaultAnswersPerLanguage } from '../../../../../../store/utils/defaultAnswers';
import { isDefined, useLanguage } from '../../../../../../utils';
import styles from './DashboardNoData.module.scss';

const DashboardNoData: React.FC<SessionProps> = ({ session }) => {
  const { language, question } = session;
  const { language: browserLanguage } = useLanguage();

  const message = useMemo(() => {
    if (!isDefined(language)) {
      return knowledgeBaseDefaultAnswersPerLanguage[browserLanguage](question);
    }

    const messageCreator =
      knowledgeBaseDefaultAnswersPerLanguage[language] ||
      knowledgeBaseDefaultAnswersPerLanguage[browserLanguage];

    return messageCreator(question);
  }, [language, browserLanguage, question]);

  return <p className={styles.container}>{message}</p>;
};

export default DashboardNoData;
