import { Theme } from '@mui/material';
import { AsyncThunkAction } from '@reduxjs/toolkit';

import { AppThunkConfig } from '../store/store';

export interface AssistantProps {
  environment: EnvironmentName;
  isMocking: boolean;
  theme: Theme;
  httpUrl: string;
  wsUrl: string;
  mixpanelToken: string;
  logError: (msg: string) => void;
  logInfo: (msg: string) => void;
}

export type EnvironmentName = 'development' | 'production';

export enum DocumentType {
  Pdf = 'Pdf',
  Xml = 'Xml',
  Html = 'Html',
  None = 'None',
}

export enum FetchingStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum TimeRange {
  ONE_YEAR = '1-year',
  SIX_MONTHS = '6-months',
  ONE_MONTH = '1-month',
  ONE_WEEK = '1-week',
}

export enum FeedbackType {
  NONE = 'None',
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
}

export enum FeedbackReason {
  WRONG_ROOT_CAUSE = 'WrongRootCause',
  IRRELEVANT_DOCUMENTS = 'IrrelevantDocuments',
  WRONG_PARTS = 'WrongParts',
  POSITIVE = '', // TODO: reason is required on the BE, we send an empty string for positive feedbacks
  OTHER = 'Other',
}

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type ApiCall<R, A> =
  | ReturnType<AsyncThunkAction<R, A, AppThunkConfig>>
  | undefined;

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface Sorting<T extends string = string> {
  field: T;
  direction: SortDirection;
}
