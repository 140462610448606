import { SortDirection, Sorting } from './common';

export interface FileInfo {
  document_id: string;
  filename: string;
  url: string;
  size: string;
  timestamp: number; // MS time but in seconds...
  tags?: string[];
}

export interface DocumentsResponse {
  data: {
    items: FileInfo[];
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

export interface FetchDocumentsResults {
  documents: AppDocument[];
  totalElements: number;
}

export interface AppDocument {
  id: string;
  name: string;
  url: string;
  size: string;
  created: number;
  tags: string[];
}

export enum DocumentStatus {
  Pending = 'Pending',
  Uploaded = 'Uploaded',
  Error = 'Error',
}

export interface NewDocument {
  id: string;
  name: string;
  url: string | null;
  size: number;
  created: number;
  tags: string[];
  status: DocumentStatus;
}

export interface UploadedDocumentResults {
  name: string;
  url: string | null;
  status: DocumentStatus;
}

export enum DocumentField {
  Name = 'name',
  Size = 'size',
  Date = 'created',
}

export type DocumentSorting = Sorting<DocumentField>;

export interface DocumentsRequestParams {
  // In our case, it is always an array with 1 element when sorting is active, or an empty array otherwise.
  // Reason: this is how material handles it and we want to minimize state management differences.
  sorting: DocumentSorting;
  pageSize: number;
  currentPage: number;
}

export interface DocumentsFilterState extends DocumentsRequestParams {
  totalElements: number;
}

export interface DocumentsParams extends DocumentsFilterState {
  onSortingChange: (sorting: DocumentSorting) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

export const DOCUMENTS_PAGE_SIZE_OPTIONS = [10, 25, 50];

export const DOCUMENTS_DEFAULT_PAGE_SIZE = 10;
export const DOCUMENTS_DEFAULT_SORTING: DocumentSorting = {
  field: DocumentField.Date,
  direction: SortDirection.Descending,
};

export const DOCUMENTS_DEFAULT_FILTER_STATE: DocumentsFilterState = {
  sorting: DOCUMENTS_DEFAULT_SORTING,
  pageSize: DOCUMENTS_DEFAULT_PAGE_SIZE,
  currentPage: 0,
  totalElements: -1,
};
