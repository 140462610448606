interface PredictionsMockData {
  answer: string;
  jobIds: string[];
  parts: string[];
}

interface PredictionsPartialMockData {
  answer: string;
}

export const bladePartialSeed: PredictionsPartialMockData[] = [
  {
    answer: `<p>Based on past service jobs, <i>irregular blade movement</i> is often associated with:</p>`,
  },
  {
    answer: `<p><b>Physical Damage (91%)</b><br>Inspect the blades for visible damage, such as cracks, chips, or misalignment. If you find any, depending on the extent of the damage, you may need to replace or repair the blade.<br>Critical Parts: New blade sections, Hub plates, Pitch actuators<br>Similar Jobs: <a>15048</a>, <a>12937</a></p>`,
  },
  {
    answer: `<p><b>Balancing (52%)</b><br>Ensure that the blades are correctly balanced. Imbalances can lead to irregular movement. You will need a dynamic balancing machine to balance the blades accurately.<br>Similar Jobs: <a>10122</a>, <a>9837</a></p>`,
  },
  {
    answer: `<p><b>Pitch System (35%)</b><br>The pitch system adjusts the blades' angle to control the turbine's speed and rotation. Malfunctions in the pitch system can lead to irregular movement.<br>Critical Parts: Pitch actuators, Pitch control unit. Sensors and feedback devices<br>Similar Jobs: <a>10222</a>, <a>10655</a></p>`,
  },
  {
    answer: `<p><b>Diagnostic Steps</b><ol><li>Conduct a comprehensive inspection of the hydraulic system including blades, checking for leaks, pressure irregularities, and fluid levels.</li><li>Verify the functionality of the pitch control system, assessing the pitch angle response to varying wind conditions.</li><li>Calibrate and validate sensor readings, ensuring accurate data transmission to the control system.</li></ol></p><p><b>Hydraulic System Maintenance</b><ol><li>Perform regular maintenance on the hydraulic system, including fluid replacement and seal inspections, to ensure optimal functionality of the blades.</li><li>Calibrate the pitch control system to synchronize blade adjustments with wind conditions, promoting consistent and reliable movement.</li><li>Verify sensor accuracy and consider replacing faulty sensors to enhance the precision of data input for the control system.</li></ol></p><p>Sources:</p><ul><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Technical_Manual_Handbook_V2.5.pdf#page=46" target="_blank" rel="noopener noreferrer">Troubleshooting Guide for blades failure modes (Page 46)</a></li><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Preventive_Maintenance_Schedule.pdf#page=24" target="_blank" rel="noopener noreferrer">Preventive Maintenance Plan (Page 24)</a></li><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=15" target="_blank" rel="noopener noreferrer">Blades: Wind turbine system schema (Page 15)</a></li></ul>`,
  },
];

export const bladeFinalSeed: PredictionsMockData = {
  answer: bladePartialSeed.map((part) => part.answer).join(''),
  jobIds: ['15048', '12937', '10122', '98373', '10222', '10655'],
  parts: [],
};

export const generatorPartialSeed: PredictionsPartialMockData[] = [
  {
    answer: `<p>Based on past service jobs, <i>generator and power converter failures</i> are often associated with:</p>`,
  },
  {
    answer: `<p><b>Electrical Fault due to Overheating (72%)</b><br>High wind speeds can cause the turbine to produce more power than the generator and power converter can handle.<br>Critical Parts: Cooling Fans, Ventilation and Enclosures<br>Similar Jobs: <a>89122</a>, <a>69836</a></p>`,
  },
  {
    answer: `<p><b>Converter Failure caused by Cooling System Issues (64%)</b><br>Addressing the cooling system problems is crucial to prevent further damage and ensure the proper functioning of the converter. Check cooling fluid levels and verify pump operation.<br>Similar Jobs: <a>46143</a>, <a>36029</a></p>`,
  },
  {
    answer: `<p><b>Control System Malfunction (38%)</b><br>Conduct thorough electrical system analysis to identify and address overloads, voltage fluctuations, and power quality problems. Ensure that the turbine's control system and software are up-to-date.<br>Critical Parts: Generator coils, Control Board<br>Similar Jobs: <a>89922</a>, <a>89836</a></p>`,
  },
  {
    answer: `<p><b>Converter Mechanism</b><ol><li>The converter allows independent control of real and reactive flow in either direction (grid to rotor) or confined to unidirectional (rotor to grid) real power flow.</li><li>With DTC technology field orientation is achieved by using advanced generator theory to calculate the accurate generator torque. The performance of DTC controlled wind turbine converter is most effective, and benefits are e.g., fast torque response, accurate control also at low frequencies, torque repeatability and accuracy of dynamic and static speed operations.</li><li>In the start-up procedure, the converter is operated locally from DriveWindow PC tool. The start-up mainly uses rotor-side converter parameters. When grid-side converter parameters are needed, a reference to the grid-side converter parameter list in ACS800-67(LC) doubly fed induction generator control program firmware manual is given.</li></ol></p><p>Sources:</p><ul><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=14" target="_blank" rel="noopener noreferrer">Wind Turbine Components and functionality of Converter (Page 14)</a></li><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=22" target="_blank" rel="noopener noreferrer">Control of torque and reactive power via rotor-side converter (Page 22)</a></li><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=56" target="_blank" rel="noopener noreferrer">How to start-up the converter (Page 56)</a></li></ul>`,
  },
];

export const generatorFinalSeed: PredictionsMockData = {
  answer: generatorPartialSeed.map((part) => part.answer).join(''),
  jobIds: ['68122', '69836', '46143', '36029', '89922', '89836'],
  parts: [],
};

export const pitchPartialSeed: PredictionsPartialMockData[] = [
  {
    answer: `<p>Based on past service jobs, <i>pitch-bearing failures</i> are often associated with:</p>`,
  },
  {
    answer: `<p><b>PCS malfunction (81%)</b><br>Electrical analysis of the pitch control system, addressing and repairing any faults or malfunctions. Install additional safeguards, such as surge protectors and voltage regulators.<br>Similar Jobs: <a>68122</a>, <a>89522</a></p>`,
  },
  {
    answer: `<p><b>Rolling Element Fatigue (66%)</b><br>Prolonged operation under heavy loads, high rotational speeds, or insufficient lubrication can fatigue these rolling elements. Regular inspection and monitoring of rolling elements are essential to identify early signs of fatigue and prevent catastrophic failure.<br>Critical Parts: Lubricants, Monitoring Sensors, Bearing Isolators<br>Similar Jobs: <a>81552</a>, <a>83556</a></p>`,
  },
  {
    answer: `<p><b>Bearing Seal Degradation (62%)</b><br>The seals in pitch bearings prevent dust, moisture, and other environmental contamination. If these seals degrade over time due to wear, improper installation, or exposure to harsh conditions, contaminants can be ingested.<br>Critical Parts: Bearings, Seals & Lubrication<br>Similar Jobs: <a>89552</a>, <a>89556</a></p>`,
  },
  {
    answer: `<p><b>Electrical malfunctions</b><ol><li>The replacement of pitch bearings in a wind turbine are often linked to erratic pitch adjustments within specific speed operating ranges.</li><li>Fluctuations in electrical signals, such as short circuits or voltage irregularities, can disrupt the precise control of pitch angles during critical speed areas.</li></ol><b>Inadequate lubrication</b><ol><li>Without proper lubrication, the bearings experience premature deterioration, compromising their structural integrity and overall performance.</li><li>The use of high-performance lubricants capable of withstanding the demanding operational conditions helps mitigate friction-related wear and extends the lifespan of the pitch bearings.</li></ol></p><p>Sources:</p><ul><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=20" target="_blank" rel="noopener noreferrer">System operating speed area (Page 20)</a></li><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Technical_Manual_Handbook_V2.5.pdf#page=87" target="_blank" rel="noopener noreferrer">Inadequate lubrication (Page 87)</a></li><li><a href="https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Preventive_Maintenance_Schedule.pdf#page=24" target="_blank" rel="noopener noreferrer">Preventive Maintenance Plan (Page 24)</a></li></ul>`,
  },
];

export const pitchFinalSeed: PredictionsMockData = {
  answer: pitchPartialSeed.map((part) => part.answer).join(''),
  jobIds: ['89122', '89522', '81552', '83556', '89552', '89556'],
  parts: [],
};
