export const knowledgeBaseDefaultAnswers: string[] = [
  'No information can be found for the question asked. Please provide more details.',
  'Zu der gestellten Frage können keine Informationen gefunden werden. Bitte geben Sie weitere Details an.',
  'Aucune information ne peut être trouvée pour la question posée. Veuillez fournir plus de détails.',
];

export const knowledgeBaseDefaultAnswersPerLanguage: Record<
  string,
  (question: string) => string
> = {
  en: (question) =>
    `I can't find any information related to ${question}. There are no answers or suggestions for the information you have provided. Please make sure that you have correctly entered the text or try another query.`,
  de: (question) =>
    `Ich kann keine Informationen zu ${question} finden. Es gibt keine Antworten oder Vorschläge für die Informationen, die Sie angegeben haben. Bitte vergewissern Sie sich, dass Sie den Text richtig eingegeben haben, oder versuchen Sie eine andere Abfrage.`,
  fr: () =>
    'Aucune information ne peut être trouvée pour la question posée. Veuillez fournir plus de détails.',
};
