import { createAsyncThunk } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import { Customer } from '../../integration/customers.api';
import { fetchPastJobs } from '../../integration/pastJobs.api';
import { updateCustomerSession } from '../slices/customerDetails.slice';
import { updateEquipmentSession } from '../slices/conversations.slice';

interface GetCustomerPastJobsParams extends ApiThunkParams {
  customer: Customer;
}

export const getCustomerPastJobs = createAsyncThunk<
  void,
  GetCustomerPastJobsParams,
  AppThunkConfig
>(
  'getCustomerPastJobs',
  async ({ customer, baseUrl, mock, logError }, { dispatch, signal }) => {
    try {
      const jobs = await fetchPastJobs({
        signal,
        baseUrl,
        mock,
        customerId: customer.id,
      });
      dispatch(
        updateCustomerSession({
          id: customer.id,
          pastJobs: jobs,
          pastJobsStatus: FetchingStatus.SUCCESS,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch past jobs for customer ${customer.id}. Reason: ${error?.message}`,
      );
      dispatch(
        updateCustomerSession({
          id: customer.id,
          pastJobsStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);

interface GetEquipmentPastJobsParams extends ApiThunkParams {
  sessionId: string;
  jobIds: string[];
}

export const getEquipmentPastJobs = createAsyncThunk<
  void,
  GetEquipmentPastJobsParams,
  AppThunkConfig
>('getEquipmentPastJobs', async (params, { dispatch, signal }) => {
  const { sessionId, jobIds, baseUrl, mock, logError } = params;
  if (jobIds.length === 0) {
    dispatch(
      updateEquipmentSession({
        id: sessionId,
        jobsStatus: FetchingStatus.SUCCESS,
        jobs: [],
      }),
    );
    return;
  }
  try {
    const jobs = await fetchPastJobs({
      signal,
      baseUrl,
      mock,
      jobIds,
    });
    dispatch(
      updateEquipmentSession({
        jobs,
        id: sessionId,
        jobsStatus: FetchingStatus.SUCCESS,
      }),
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    logError(
      `Could not fetch past jobs for session ${sessionId}. Reason: ${error?.message}`,
    );
    dispatch(
      updateEquipmentSession({
        id: sessionId,
        jobsStatus: FetchingStatus.ERROR,
      }),
    );
  }
});
