import React from 'react';

import { Translations } from './common';
import { JobType, SurveyStatus } from '../types';
import { SearchHistoryEntrySegment } from '../components/SmartLoopContainer/DrawerContent/SearchHistory/UseSearchHistory';

const englishTranslations: Translations = {
  loading: 'Searching for an answer',
  error: (
    <div>
      Oops.
      <br />
      Looks like this section is not working. Try again later or send us a{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        message
      </a>
      .
    </div>
  ),
  auth: {
    login: 'Login',
    email: 'Email',
    password: 'Password',
    or: 'or',
    loading: 'Authenticating...',
    marketingMessage: 'Ready for Take Off?',
    invalidCredentials: 'Invalid email or password',
    sessionExpired:
      'Your session has expired. Please log in again to continue using InsightLoop.',
    pilotDescription: (
      <>
        Partnering for Success: Our Collaborative Approach to Empower Your
        Journey. Let us help you to unlock the power of your data –{' '}
        <a href="mailto:sales@coresystems.ch" target="">
          request
        </a>{' '}
        your access now.
      </>
    ),
    unauthorized:
      'The Assistant is currently only available for early-access clients.',
    azure: {
      loginButtonText: 'Login with Microsoft',
      tooltipLabel: 'Need help?',
      tooltipContent:
        'This is the same login you use to access Azure. If not sure, please contact IT support.',
    },
  },
  noData: {
    defaultMessage: 'We could not load any data. Try changing a filter.',
    openJobs: 'There are currently no open jobs available.',
    pastJobs: 'There is currently no service history available.',
    images: 'There are currently no images available.',
    customerSummary: 'There is currently no customer summary available.',
    diagnoses: 'There are currently no diagnoses available.',
    tags: 'There are currently no tags available.',
    documents: 'There are currently no documents available.',
  },
  deleteConfirmationDialog: {
    title: 'Delete Thread?',
    description: (label) => (
      <span>
        This will delete your search thread <b>“{label}”</b>.
      </span>
    ),
    cancel: 'Cancel',
    confirm: 'Delete',
  },
  assistant: {
    noAnswer: 'Sorry, I cannot seem to find an answer to that.',
    noCustomer: 'No customer available',
    noDate: 'No date available',
    noEquipment: 'No equipment available',
    noDivision: 'No division available',
    noCode: 'No job nr. available',
    noFileName: 'No file name available',
    noFileSize: 'No file size available',
    equipmentSearchPlaceholder: 'Select equipment...',
    customerSearchPlaceholder: 'Select customer...',
    filterLoading: 'Searching...',
    filterNoResults: 'No results',
    questionPlaceholder: 'Type your question here...',
    followUpQuestionPlaceholder: 'Ask follow up question...',
    activeFilterToggle: {
      customer: 'Customer',
      equipment: 'Equipment',
    },
    jobType: {
      [JobType.Failure]: 'Failure',
      [JobType.Maintenance]: 'Maintenance',
      [JobType.Other]: 'Other',
    },
    drawer: {
      newSearch: 'New Search',
      tipsTitle: 'Navigating Tips',
      tipsContent: `Ask follow up questions for the same equipment, or click “New Search” to start another topic.`,
      segments: {
        [SearchHistoryEntrySegment.Today]: 'Today',
        [SearchHistoryEntrySegment.Yesterday]: 'Yesterday',
        [SearchHistoryEntrySegment.LastWeek]: 'Previous 7 days',
        [SearchHistoryEntrySegment.OlderThanLastWeek]: 'Older',
      },
    },
    troubleshooting: {
      toggleLabel: 'Troubleshooting',
      title: 'Guided Troubleshooting',
      description:
        'Based on confirmed symptoms, problem descriptions or error codes, you will be guided through the troubleshooting process.',
      symptoms: 'Known Symptoms:',
      symptomSearchPlaceholder: 'Search symptoms...',
      skipBtn: 'Skip for now',
      startBtn: 'Start',
      finishBtn: 'Finish troubleshooting',
      closeBtn: 'Close',
      symptomSelectorLabel:
        'Enter further symptoms or select from related symptoms:',
      relatedSymptoms: 'Related symptoms:',
      answerSummary: 'Details',
      resumeTroubleshooting: 'Reopen Troubleshooting Tree',
      completedTitle: 'Troubleshooting Completed',
      finalSymptoms: 'Final symptoms:',
      successMessage: 'Successfully diagnosed.',
      rootCause: 'Root cause:',
      technicians: (technicians) => (
        <>
          <em>{technicians[0]}</em> and <em>{technicians.length - 1} other</em>{' '}
          technicians had this root cause.
        </>
      ),
      step: 'Step',
      diagnosisSummary: ({ diagnoses, symptoms }) => {
        if (symptoms.length === 0) {
          return 'Please select a symptom to proceed.';
        }
        const numberOfDiagnoses =
          diagnoses === null || diagnoses.length === 0
            ? 'No root causes'
            : `${diagnoses.length} root cause${
                diagnoses.length > 1 ? 's' : ''
              }`;
        const quotedSymptoms = symptoms.map((symptom) => `“${symptom}”`);
        if (symptoms.length < 3) {
          return `${numberOfDiagnoses} found based on ${quotedSymptoms.join(
            ' and ',
          )}.`;
        }
        const allButTheLastSymptom = symptoms.slice(0, -1);
        const lastSymptom = symptoms[symptoms.length - 1];

        return `${numberOfDiagnoses} found based on ${allButTheLastSymptom.join(
          ', ',
        )} and ${lastSymptom}.`;
      },
      tooltipSummary:
        'Static sources, such as documents including fault trees, are predefined, while dynamic sources are continuously derived from analyzed service case data.',
      tooltipDocumentSource: 'Documents',
      tooltipServiceCaseSource: 'Service Cases',
    },
    home: {
      mainTitle: (
        <span>
          Your <em>Service Knowledge</em> Base
        </span>
      ),
      openJobsTitle: 'Open Jobs',
      openJobsHeaders: {
        created: 'Created',
        equipment: 'Equipment',
        customer: 'Customer',
        symptom: 'Reported Issue',
        rootCause: 'Insights',
        division: 'Division',
        jobCode: 'Job Nr.',
      },
      openJobsFilterValuePlaceholder: 'Search...',
      noPrediction: 'No prediction or prediction confidence is too low (<10%)',
    },
    dashboard: {
      answer: 'Answer',
      similarServiceJobs: 'Similar Service Jobs',
      serviceHistory: 'Service History',
      mostFrequentIssuesTitle: (equipmentType) =>
        `Most Frequent Issues for ${equipmentType}`,
      imagesTitle: 'Images from the Field',
      sources: 'Sources',
      page: 'Page',
      searchSuggestionsTitle: 'Related Topics',
    },
    customerScreen: {
      summary: 'Customer Summary',
      kpi: {
        title: 'KPIs',
        lessThanAverage: 'less than global average',
        moreThanAverage: 'more than global average',
        ftfr: 'First Time Fix Rate',
        ftfrAbbr: 'FTFR',
        mttr: 'Mean Time To Resolution',
        mttrAbbr: 'MTTR',
        mtbv: 'Mean Time Between Visits',
        mtbvAbbr: 'MTBV',
        dayUnit: (value) => (value < 2 ? ' day' : ' days'),
      },
    },
    feedback: {
      prompt: 'Was the answer helpful?',
      positive: 'Yes',
      negative: 'No',
      thanks: 'Thank you for your feedback.',
      wrongRootCause: 'Wrong root cause',
      wrongDocuments: 'Irrelevant documents',
      wrongParts: 'Wrong parts',
      other: 'Other',
      detailsPlaceholder: 'Provide additional feedback',
      submit: 'Submit',
      forceFeedbackPrompt: 'Take a moment to leave a feedback',
    },
    curationHub: {
      recentSurveys: 'Recent Surveys',
      survey: {
        surveyStatuses: {
          [SurveyStatus.Open]: 'Pending',
          [SurveyStatus.InProgress]: 'In progress',
          [SurveyStatus.Closed]: 'Completed',
        },
        open: 'Open',
      },
      surveyDetails: {
        title: (equipmentType) => `Questions for ${equipmentType}`,
        prompt: 'Select one or more answers.',
        moreInformation: 'More information (optional)',
        questionJobIds: (jobId) => (
          <span>
            Question generated using job <em>{jobId}</em>
          </span>
        ),
        answerJobIds: (jobIds) => (
          <span>
            Answers generated using job{jobIds.length > 1 ? 's' : ''}{' '}
            <em>{jobIds.join(', ')}</em>
          </span>
        ),
        next: 'Next',
        back: 'Back',
      },
      surveyCompleted: {
        thanks: 'Thank you!',
        completionText: (equipmentType) => (
          <span>
            You have successfully completed the <b>{equipmentType}</b> survey.
          </span>
        ),
        close: 'Close',
      },
    },
    documentManagement: {
      homeTitle: (
        <span>
          Your <em>Service</em> Assistant
        </span>
      ),
      configurationLabel: 'Configuration',
      documentOverview: 'Document Overview',
      tagManagement: 'Tag Management',
      customerInformation: 'Customer Information',
      tagsPlaceholder: 'Search for tags...',
      equipmentTypesTitle: 'Equipment Types',
      documentsPlaceholder: 'Search for your documents...',
      uploadDocumentsButtonLabel: 'Upload Documents',
      uploadDialogTitle: 'Upload Documents',
      cancel: 'Cancel',
      close: 'Close',
      fileDropZoneLabel: (
        <span>
          <em>Click to upload</em> or drag and drop
        </span>
      ),
      fileName: 'Document',
      tags: 'Tags',
      date: 'Date',
      fileSize: 'Size',
    },
  },
};

export default englishTranslations;
