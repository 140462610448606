import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v1 as generateId } from 'uuid';

import {
  AppDocument,
  DOCUMENTS_DEFAULT_FILTER_STATE,
  DocumentsFilterState,
  DocumentSorting,
  DocumentsRequestParams,
  DocumentStatus,
  FetchDocumentsResults,
  FetchingStatus,
  NewDocument,
  UploadedDocumentResults,
} from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import {
  deleteDocument,
  fetchDocuments,
  uploadAndRegisterDocuments,
} from '../../integration/documents.api';
import { useAppSelector } from '../utils/hooks';

export const DOCUMENTS_KEY = 'documents';

export interface DocumentsState extends DocumentsFilterState {
  status: FetchingStatus;
  documents: AppDocument[] | null;
  totalElements: number;
  newDocuments: NewDocument[];
}

const initialState: DocumentsState = {
  ...DOCUMENTS_DEFAULT_FILTER_STATE,
  status: FetchingStatus.IDLE,
  documents: null,
  totalElements: -1,
  newDocuments: [],
};

export const getDocuments = createAsyncThunk<
  FetchDocumentsResults,
  ApiThunkParams & DocumentsRequestParams,
  AppThunkConfig
>(
  'getDocuments',
  async ({ baseUrl, mock, sorting, currentPage, pageSize }, { signal }) => {
    return fetchDocuments({
      sorting,
      currentPage,
      pageSize,
      signal,
      baseUrl,
      mock,
    });
  },
);

interface UploadDocumentsParams extends ApiThunkParams {
  equipmentTypes: string[];
  files: File[];
}

export const uploadDocuments = createAsyncThunk<
  UploadedDocumentResults[],
  UploadDocumentsParams,
  AppThunkConfig
>(
  'uploadDocuments',
  async ({ equipmentTypes, files, baseUrl, mock, logError }, { signal }) => {
    return uploadAndRegisterDocuments({
      equipmentTypes,
      files,
      signal,
      baseUrl,
      mock,
      logError,
    });
  },
);

export interface RemoveDocumentParams extends ApiThunkParams {
  id: string;
  name: string;
}

export const removeDocument = createAsyncThunk<
  void,
  RemoveDocumentParams,
  AppThunkConfig
>('removeDocument', async ({ id, name, baseUrl, mock }, { signal }) => {
  return deleteDocument({
    id,
    name,
    signal,
    baseUrl,
    mock,
  });
});

const documentsSlice = createSlice({
  name: DOCUMENTS_KEY,
  initialState,
  reducers: {
    updateDocumentSorting: (state, action: PayloadAction<DocumentSorting>) => ({
      ...state,
      sorting: action.payload,
    }),
    updateDocumentPageSize: (state, action: PayloadAction<number>) => ({
      ...state,
      pageSize: action.payload,
    }),
    updateDocumentPage: (state, action: PayloadAction<number>) => ({
      ...state,
      currentPage: action.payload,
    }),
    updateDocumentTags: (state, action: PayloadAction<string[]>) => ({
      ...state,
      tags: action.payload,
    }),
    clearNewDocuments: (state) => ({
      ...state,
      newDocuments: [],
    }),
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getDocuments.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.documents = action.payload.documents;
        state.totalElements = action.payload.totalElements;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.documents = null;
        state.totalElements = 0;
      })
      .addCase(uploadDocuments.pending, (state, action) => {
        const { equipmentTypes } = action.meta.arg;
        const files = action.meta.arg.files.map((file) => ({
          id: generateId(),
          name: file.name,
          size: file.size,
          tags: equipmentTypes,
          url: null,
          created: new Date().getTime(),
          status: DocumentStatus.Pending,
        }));
        const newFiles = files.filter(
          (file) =>
            state.newDocuments.find((doc) => doc.name === file.name) ===
            undefined,
        );
        const reUploadedFiles = files.filter(
          (file) =>
            state.newDocuments.find((doc) => doc.name === file.name) !==
            undefined,
        );
        state.newDocuments = [
          ...newFiles,
          ...state.newDocuments.map((doc) => {
            const reUploadedFile = reUploadedFiles.find(
              (file) => file.name === doc.name,
            );
            if (reUploadedFile === undefined) {
              return doc;
            } else {
              return {
                ...reUploadedFile,
                status: DocumentStatus.Pending,
              };
            }
          }),
        ];
      })
      .addCase(uploadDocuments.fulfilled, (state, action) => {
        state.newDocuments = state.newDocuments.map((doc) => {
          const match = action.payload.find((file) => file.name === doc.name);
          if (match === undefined) {
            return doc;
          } else {
            return {
              ...doc,
              url: match.url,
              status: match.status,
            };
          }
        });
      })
      .addCase(uploadDocuments.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.newDocuments = state.newDocuments.map((doc) => {
          const match = action.meta.arg.files.find(
            (file) => file.name === doc.name,
          );
          if (match === undefined) {
            return doc;
          } else {
            return {
              ...doc,
              status: DocumentStatus.Error,
            };
          }
        });
      })
      .addCase(removeDocument.pending, (state, action) => {
        if (state.documents !== null) {
          state.documents = state.documents.filter(
            (doc) => doc.id !== action.meta.arg.id,
          );
        }
      });
  },
});

const { actions, reducer } = documentsSlice;

export const {
  updateDocumentPageSize,
  updateDocumentPage,
  updateDocumentSorting,
  updateDocumentTags,
  clearNewDocuments,
  reset: resetDocuments,
} = actions;

export const useDocumentUploadFinished = (): boolean => {
  const { newDocuments } = useAppSelector((state) => state.documents);
  if (newDocuments.length === 0) {
    return true;
  }

  return newDocuments.every((doc) => doc.status !== DocumentStatus.Pending);
};

export const documentsReducer = reducer;
