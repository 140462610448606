import React, { useEffect } from 'react';
import { format } from 'date-fns';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import clsx from 'clsx';

import {
  AssistantProps,
  DOCUMENTS_PAGE_SIZE_OPTIONS,
  DocumentField,
  DocumentSorting,
} from '../../../../../types';
import {
  getSortingFromColumn,
  isDefined,
  renderOrDefault,
  useIsDarkMode,
  useTranslations,
} from '../../../../../utils';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/utils/hooks';
import {
  getDocuments,
  removeDocument,
  updateDocumentPage,
  updateDocumentPageSize,
  updateDocumentSorting,
} from '../../../../../store/slices/documents.slice';
import SmartLoopCardContent from '../../../../Card/SmartLoopCardContent';
import TableHeader from '../../../../Table/Header/TableHeader';
import styles from './DocumentTable.module.scss';

interface Props extends AssistantProps {
  // We need this to force a documents reload when an upload process is finished
  reloadCounter: number;
}

const DocumentTable: React.FC<Props> = ({ reloadCounter, ...props }) => {
  const { httpUrl, isMocking, logError } = props;
  const { assistant: translations, noData } = useTranslations();
  const darkMode = useIsDarkMode();
  const dispatch = useAppDispatch();
  const { totalElements, pageSize, currentPage, documents, status, sorting } =
    useAppSelector((state) => state.documents);

  useEffect(() => {
    const promise = dispatch(
      getDocuments({
        sorting,
        pageSize,
        currentPage,
        logError,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    httpUrl,
    isMocking,
    sorting,
    pageSize,
    currentPage,
    reloadCounter,
  ]);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    dispatch(updateDocumentPage(newPage));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    dispatch(updateDocumentPageSize(newPageSize));
    dispatch(updateDocumentPage(0));
  };
  const handleColumnClicked = (column: string) => {
    const newSorting = getSortingFromColumn(column, sorting);
    dispatch(updateDocumentSorting(newSorting as DocumentSorting));
  };
  const handleDelete = (id: string, name: string) => {
    dispatch(
      removeDocument({ id, name, logError, baseUrl: httpUrl, mock: isMocking }),
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <SmartLoopCardContent
          data={documents}
          status={status}
          emptyStateMessage={noData.documents}
        >
          {(documents) => (
            <Table aria-label="documents table">
              <TableHead>
                <TableRow>
                  <TableHeader
                    field={DocumentField.Name}
                    handleColumnClicked={handleColumnClicked}
                    sorting={sorting}
                    title={translations.documentManagement.fileName}
                  />
                  <TableCell>{translations.documentManagement.tags}</TableCell>
                  <TableHeader
                    field={DocumentField.Date}
                    handleColumnClicked={handleColumnClicked}
                    sorting={sorting}
                    title={translations.documentManagement.date}
                  />
                  <TableHeader
                    field={DocumentField.Size}
                    handleColumnClicked={handleColumnClicked}
                    sorting={sorting}
                    title={translations.documentManagement.fileSize}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((document) => (
                  <TableRow key={document.id}>
                    <TableCell>
                      {renderOrDefault(document.name, translations.noFileName)}
                    </TableCell>
                    <TableCell>
                      <div className={styles.tags}>
                        {document.tags.map((tag) => (
                          <div
                            key={tag}
                            className={clsx(
                              styles.tag,
                              darkMode && styles.darkMode,
                            )}
                          >
                            {tag}
                          </div>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      {isDefined(document.created)
                        ? format(document.created, 'dd.MM.yyyy')
                        : translations.noDate}
                    </TableCell>
                    <TableCell>
                      <div className={styles.interactions}>
                        <span className={styles.size}>
                          {renderOrDefault(
                            document.size,
                            translations.noFileSize,
                          )}
                        </span>
                        <a
                          href={document.url}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <DownloadIcon className={styles.downloadIcon} />
                        </a>
                        <DeleteIcon
                          className={styles.deleteIcon}
                          onClick={() =>
                            handleDelete(document.id, document.name)
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </SmartLoopCardContent>
      </div>
      <TablePagination
        component="div"
        rowsPerPageOptions={DOCUMENTS_PAGE_SIZE_OPTIONS}
        count={totalElements}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default DocumentTable;
