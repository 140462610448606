import React from 'react';

import { FetchingStatus, SessionProps } from '../../../../../types';
import { isAnswerEmpty, useTranslations } from '../../../../../utils';
import { ReactComponent as AnswerIcon } from './assets/answer.svg';
import CollapsibleCard from '../../../../Card/Collapsible/CollapsibleCard';
import EquipmentSessionAnswerContent from './Content/EquipmentSessionAnswerContent';
import Feedback from './Feedback/Feedback';
import FeedbackForm from './Feedback/Form/FeedbackForm';
import Responsive from '../../../../Responsive/Responsive';
import AppDialog from '../../../../Dialog';
import DashboardNoData from './NoData/DashboardNoData';
import Error from '../../../../Card/Error/Error';
import styles from './EquipmentSessionAnswer.module.scss';

const EquipmentSessionAnswer: React.FC<SessionProps> = (props) => {
  const { session } = props;

  const {
    assistant: { dashboard: translations },
  } = useTranslations();
  const title = {
    text: translations.answer,
    icon: <AnswerIcon className={styles.titleIcon} />,
  };
  const noData = isAnswerEmpty(session);
  const isError = session.answerStatus === FetchingStatus.ERROR;

  return (
    <div>
      <CollapsibleCard title={title} openByDefault>
        <EquipmentSessionAnswerContent {...props} />
        {noData && <DashboardNoData {...props} />}
        {isError && <Error />}
        {session.answerStatus === FetchingStatus.SUCCESS && (
          <Feedback {...props} />
        )}
      </CollapsibleCard>
      {session.feedback.open && (
        <Responsive
          mobileComponent={
            <AppDialog
              open
              showCloseIcon={false}
              className={styles.feedbackDialog}
            >
              <FeedbackForm {...props} />
            </AppDialog>
          }
          desktopComponent={<FeedbackForm {...props} />}
        />
      )}
    </div>
  );
};

export default EquipmentSessionAnswer;
