export interface SearchExample {
  equipmentType: string;
  symptom: string;
}

export const searchExamplesPerTenant: Record<number, SearchExample[]> = {
  100002: [
    {
      equipmentType: 'Perfect binder Vareo 3040.0410',
      symptom: 'Positionsfehler Antrieb Lift',
    },
    {
      equipmentType: 'Perfect binder Vareo PRO 3040.0413',
      symptom: 'Filtermatte reinigen und Ölstand prüfen',
    },
    {
      equipmentType: 'Book Production Line BF 530 K332.0100',
      symptom: 'Unzureichende Rundung im Buchblock',
    },
  ],
  100003: [
    { equipmentType: 'RPSUK12V1600-2B0', symptom: 'Fuel pressure faults' },
    {
      equipmentType: '12V1600R80L',
      symptom: 'High SCR upstream temps with low CAP',
    },
    {
      equipmentType: '12V1600R80L',
      symptom: 'ECU alarming for low side fuel pressure',
    },
  ],
  100004: [
    {
      equipmentType: 'Perfect binder Vareo 3040.0410',
      symptom: 'Positionsfehler Antrieb Lift',
    },
    {
      equipmentType: 'Perfect binder Vareo PRO 3040.0413',
      symptom: 'Filtermatte reinigen und Ölstand prüfen',
    },
    {
      equipmentType: 'Book Production Line BF 530 K332.0100',
      symptom: 'Unzureichende Rundung im Buchblock',
    },
  ],
  100020: [
    {
      equipmentType: 'PM Headbox',
      symptom: 'Blendenwechsel durchführen',
    },
    {
      equipmentType: 'PM Headbox',
      symptom: 'Neuste Konfiguration für den Laufsteg?',
    },
    {
      equipmentType: 'Hausmening PM5',
      symptom: 'Hausmening: Funktion der thermischen Stabilisierung',
    },
  ],
  100021: [
    {
      equipmentType: 'Getriebe 515435',
      symptom: 'Alle Infos zu Getriebe 515435',
    },
    {
      equipmentType: 'Getriebe 515096',
      symptom: 'Montagebericht 515096',
    },
    {
      equipmentType: 'Getriebe 515855',
      symptom: 'Zeichnungen 515855',
    },
  ],
};

export const mockSearchExamples: SearchExample[] = [
  {
    equipmentType: 'WT 8.0-167 DD',
    symptom: 'Irregular blade movement',
  },
  {
    equipmentType: 'WT 3.x-103',
    symptom: 'Generator failure',
  },
  {
    equipmentType: 'WT 2-B Energy 2B6',
    symptom: 'Pitch bearing failure',
  },
];
