import React from 'react';

import {
  AssistantProps,
  EquipmentSession,
  FeedbackType,
  FetchingStatus,
  TroubleshootingStatus,
} from '../../../../types';
import DashboardSession from '../Session/DashboardSession';

const DashboardLoading: React.FC<AssistantProps> = (props) => {
  return (
    <DashboardSession
      session={dummySession}
      allSessions={[dummySession]}
      containerRef={null}
      {...props}
    />
  );
};

const dummySession: EquipmentSession = {
  question: '',
  equipmentType: '',
  intent: null,
  language: null,
  allEquipmentTags: [],
  extractedSymptom: null,
  extractedSymptomStatus: FetchingStatus.PENDING,
  conversationId: 'conversation-loading',
  id: 'session-loading',
  savedToHistory: false,
  createdAt: Date.now(),
  answer: null,
  answerJobIds: null,
  answerStatus: FetchingStatus.PENDING,
  parts: null,
  images: null,
  imagesStatus: FetchingStatus.PENDING,
  suggestions: null,
  suggestionsStatus: FetchingStatus.PENDING,
  jobs: null,
  jobsStatus: FetchingStatus.PENDING,
  feedback: {
    type: FeedbackType.NONE,
    open: false,
  },
  troubleshootingVisible: false,
  troubleshootingStatus: TroubleshootingStatus.Idle,
  troubleshootingSymptoms: [],
  troubleshootingSymptomsStatus: FetchingStatus.IDLE,
  troubleshootingSelectedSymptoms: [],
  troubleshootingRelatedSymptoms: [],
  diagnosisNodes: null,
  diagnosisStatus: FetchingStatus.IDLE,
  qnaSteps: null,
  resolvedRootCauses: null,
  rootCauses: null,
  openedRootCauseId: null,
  finalRootCause: null,
};

export default DashboardLoading;
