import api from './api';
import { ApiFetchParams, FeedbackReason } from '../types';

interface FeedbackParams extends ApiFetchParams {
  sessionId: string;
  comment: string;
  reason: FeedbackReason;
  useful: boolean;
}

export const sendFeedback = async ({
  comment,
  reason,
  useful,
  sessionId,
  signal,
  mock,
  baseUrl,
}: FeedbackParams): Promise<void> => {
  await api.post<void>({
    mock,
    baseUrl,
    path: '/knowledge-base/api/v1/feedbacks',
    body: {
      comment,
      reason,
      useful,
      session_id: sessionId,
    },
    config: { signal },
  });
};
