import React from 'react';

import { ReactComponent as ErrorLight } from './assets/error-light.svg';
import { ReactComponent as ErrorDark } from './assets/error-dark.svg';
import { useIsDarkMode, useTranslations } from '../../../utils';
import styles from './Error.module.scss';

const Error: React.FC = () => {
  const translations = useTranslations();
  const darkMode = useIsDarkMode();

  return (
    <div className={styles.container}>
      {darkMode ? (
        <ErrorDark className={styles.iconDark} />
      ) : (
        <ErrorLight className={styles.iconLight} />
      )}
      <div className={styles.text}>{translations.error}</div>
    </div>
  );
};

export default Error;
