import React from 'react';
import clsx from 'clsx';

import { ReactComponent as MobileLogo } from '../../../../assets/mobile-logo.svg';
import { ReactComponent as MobileLogoLight } from '../../../../assets/mobile-logo-light.svg';
import { AssistantProps } from '../../../../types';
import {
  isOpenJobsTableEnabled,
  useIsDarkMode,
  useTranslations,
} from '../../../../utils';
import HomeHeaderInputs from './Inputs/HomeHeaderInputs';
import Responsive from '../../../Responsive/Responsive';
import styles from './HomeHeader.module.scss';

const HomeHeader: React.FC<AssistantProps> = (props) => {
  const {
    assistant: { home: translations },
  } = useTranslations();
  const darkMode = useIsDarkMode();
  const showOpenJobsTable = isOpenJobsTableEnabled();

  return (
    <div
      className={clsx(styles.container, !showOpenJobsTable && styles.centered)}
    >
      <Responsive
        mobileComponent={
          darkMode ? (
            <MobileLogo className={styles.logo} />
          ) : (
            <MobileLogoLight className={styles.logo} />
          )
        }
        desktopComponent={
          <h1 className={styles.title}>{translations.mainTitle}</h1>
        }
      />
      <HomeHeaderInputs {...props} />
    </div>
  );
};

export default HomeHeader;
