import { BaseSessionProps } from '../types';

export enum ChatbotResponseType {
  AnswerSources = 'AnswerSources',
  AnswerIntent = 'AnswerIntent',
  AnswerLanguage = 'AnswerLanguage',
  AnswerPartial = 'AnswerPartial',
  AnswerFinished = 'AnswerFinished',
  AnswerError = 'AnswerError',
  CustomerSummaryPartial = 'CustomerSummaryPartial',
  CustomerSummaryFinished = 'CustomerSummaryFinished',
  CustomerSummaryError = 'CustomerSummaryError',
}

interface BaseWebsocketResponse extends BaseSessionProps {
  message?: string; // Field is needed because Gateway API errors contain it
}

export interface AnswerSourcesResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.AnswerSources;
  jobIds: string[];
}

export enum Intent {
  Troubleshooting = 'troubleshooting',
  Summary = 'summary',
  NeedleInTheHaystack = 'needle-in-the-haystack',
  ReportSearch = 'report-search',
}

export interface AnswerIntentResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.AnswerIntent;
  intent: Intent;
}

export interface AnswerLanguageResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.AnswerLanguage;
  language: string;
}

export interface AnswerPartialResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.AnswerPartial;
  answer: string;
  index: number;
}

export interface AnswerFinishedResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.AnswerFinished;
  answer: string;
  parts?: string[];
}

export interface AnswerErrorResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.AnswerError;
  message: string;
}

export interface CustomerSummaryPartialResponse {
  type: ChatbotResponseType.CustomerSummaryPartial;
  answer: string;
  customerId: string;
  index: number;
  message?: string; // Field is needed because Gateway API errors contain it
}

export interface CustomerSummaryFinalResponse {
  type: ChatbotResponseType.CustomerSummaryFinished;
  answer: string;
  customerId: string;
  message?: string; // Field is needed because Gateway API errors contain it
}

export interface CustomerSummaryErrorResponse {
  type: ChatbotResponseType.CustomerSummaryError;
  customerId: string;
  message?: string; // Field is needed because Gateway API errors contain it
}

export type ChatbotResponse =
  | AnswerSourcesResponse
  | AnswerIntentResponse
  | AnswerLanguageResponse
  | AnswerPartialResponse
  | AnswerFinishedResponse
  | AnswerErrorResponse
  | CustomerSummaryPartialResponse
  | CustomerSummaryFinalResponse
  | CustomerSummaryErrorResponse;

export interface ChatbotQueryPayload {
  tenantId: number;
  language: string;
  token: string;
}

export interface SessionChatbotQueryPayload extends ChatbotQueryPayload {
  conversationId: string;
  sessionId: string;
  equipmentTypes: string[] | null;
  message?: string;
}

export enum WebSocketQueryAction {
  GetAnswer = 'getAnswer',
  CustomerSummary = 'customerSummary',
  Heartbeat = 'pong',
}

export interface WebSocketQuery<P extends ChatbotQueryPayload> {
  action: WebSocketQueryAction;
  payload: P;
}

export interface WebSocketInitParams {
  token: string;
  handleResponse: (response: ChatbotResponse) => void;
  wsUrl: string;
  logError: (msg: string) => void;
}

class WebSocketClient {
  private client: WebSocket | null = null;
  private interval: number | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private messageQueue: WebSocketQuery<any>[] = [];

  public init = async ({
    handleResponse,
    wsUrl,
    logError,
  }: WebSocketInitParams): Promise<void> => {
    const client = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      client.addEventListener('open', () => {
        this.interval = window.setInterval(() => {
          client.send(
            JSON.stringify({ action: WebSocketQueryAction.Heartbeat }),
          );
        }, 60 * 1000);
        client.addEventListener('message', (event: MessageEvent<string>) => {
          const response: ChatbotResponse = JSON.parse(event.data);
          handleResponse(response);
        });
        client.addEventListener('close', (event) => {
          if (this.interval !== null) {
            window.clearInterval(this.interval);
            this.interval = null;
          }
          this.client = null;
          // When the socket was abnormally closed
          if (event.code === 1006) {
            logError('Websocket connection closed abnormally!');
          }
        });
        client.addEventListener('error', (event) => {
          logError(`WebSocket error: ${event.type}`);
        });
        this.client = client;
        try {
          this.messageQueue.forEach((message) =>
            this.send(message.action, message.payload, logError),
          );
          this.messageQueue = [];
        } catch (error) {
          reject();
        }
        resolve();
      });
    });
  };

  public close = (): void => {
    if (this.client !== null) {
      this.client.close();
      this.client = null;
    }
    if (this.interval !== null) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
  };

  public send = <P extends ChatbotQueryPayload>(
    action: WebSocketQueryAction,
    payload: P,
    logError: (msg: string) => void,
  ): boolean => {
    const message: WebSocketQuery<P> = {
      action,
      payload,
    };
    if (this.client === null) {
      this.messageQueue.push(message);

      return false;
    }
    try {
      this.client.send(JSON.stringify(message));

      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.messageQueue.push(message);
      logError(`Could not send message. Reason: ${error?.message}`);

      return false;
    }
  };
}

export const wsClient = new WebSocketClient();
