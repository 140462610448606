import { EquipmentSession, FetchingStatus } from '../types';
import { isFetchingResolved } from './isFetchingResolved';
import { knowledgeBaseDefaultAnswers } from '../store/utils/defaultAnswers';
import { isDefined } from './isDefined';
import { Intent } from '../integration/webSocketClient';
import { isUserServiceExpert } from './auth';

export const isAnswerEmpty = ({
  answer,
  answerStatus,
}: EquipmentSession): boolean => {
  if (answerStatus !== FetchingStatus.SUCCESS) {
    return false;
  }
  const isDefaultAnswer = knowledgeBaseDefaultAnswers.some(
    (dfa) => dfa === answer,
  );

  return answer === null || isDefaultAnswer;
};

export const isSessionFullyLoaded = (session: EquipmentSession): boolean => {
  return isFetchingResolved(
    session.answerStatus,
    session.jobsStatus,
    session.imagesStatus,
    session.suggestionsStatus,
    session.extractedSymptomStatus,
  );
};

export const shouldShowTroubleshooting = (
  session: EquipmentSession,
): boolean => {
  const userServiceExpert = isUserServiceExpert();
  // To make it backward compatible, we consider null values the right intent.
  const intentIsTroubleshooting =
    !isDefined(session.intent) || session.intent === Intent.Troubleshooting;

  return (
    session.answerStatus === FetchingStatus.SUCCESS &&
    userServiceExpert &&
    intentIsTroubleshooting
  );
};
