import React, { useMemo } from 'react';
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { createAppTheme } from './theme/createAppTheme';
import { useIsDarkMode, useLogger, isInsideFSM } from './utils';
import { azureConfig } from './components/Auth/login/azureConfig';
import WithAuthentication from './components/Auth/withAuthentication/WithAuthentication';
import { EnvironmentName } from './types';
import SmartLoop from './components/SmartLoop';
import './shared-styles.scss';

const httpUrl = process.env.REACT_APP_API_URL || '';
const wsUrl = process.env.REACT_APP_WS_URL || '';
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || '';

// This function exports the material theme color variables to our global css space as css variables.
// It can be extended if more colors are needed.
const setGlobalStyles = (theme: Theme) => (
  <GlobalStyles
    styles={{
      ':root': {
        '--color-primary': theme.palette.primary.main,
        '--color-primary-light': theme.palette.primary.light,
        '--color-primary-dark': theme.palette.primary.dark,
        '--color-secondary': theme.palette.secondary.main,
        '--color-secondary-dark': theme.palette.secondary.dark,
        '--color-secondary-light': theme.palette.secondary.light,
        '--color-success': theme.palette.success.main,
        '--color-success-light': theme.palette.success.light,
        '--color-error': theme.palette.error.main,
        '--color-error-light': theme.palette.error.light,
        '--color-warning': theme.palette.warning.main,
        '--color-info': theme.palette.info.main,
        '--color-info-dark': theme.palette.info.dark,
        '--color-background': theme.palette.background.default,
        '--color-background-paper': theme.palette.background.paper,
        '--color-light-grey': theme.palette.grey.A100,
        '--color-medium-grey': theme.palette.grey.A200,
        '--color-grey': theme.palette.grey.A400,
        '--color-dark-grey': theme.palette.grey.A700,
      },
    }}
  />
);

const App: React.FC = () => {
  const prefersDarkMode = useIsDarkMode();
  const theme = useMemo(() => {
    return createAppTheme(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);
  const environment = (process.env.REACT_APP_ENVIRONMENT_NAME ||
    'development') as EnvironmentName;
  const isInsideFSMShell = isInsideFSM();
  const { logError, logInfo } = useLogger();
  const msalInstance = useMemo(() => {
    if (isInsideFSMShell) {
      return null;
    }

    return new PublicClientApplication(azureConfig);
  }, [isInsideFSMShell]);

  if (isInsideFSMShell) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {setGlobalStyles(theme)}
          <div id="smart-loop-ruler" />
          <WithAuthentication theme={theme}>
            {(isMocking) => (
              <SmartLoop
                environment={environment}
                isMocking={isMocking}
                theme={theme}
                httpUrl={httpUrl}
                wsUrl={wsUrl}
                mixpanelToken={mixpanelToken}
                logError={(msg) => logError(msg)}
                logInfo={(msg) => logInfo(msg)}
              />
            )}
          </WithAuthentication>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {setGlobalStyles(theme)}
        <div id="smart-loop-ruler" />
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <MsalProvider instance={msalInstance!}>
          <WithAuthentication theme={theme}>
            {(isMocking) => (
              <SmartLoop
                environment={environment}
                isMocking={isMocking}
                theme={theme}
                httpUrl={httpUrl}
                wsUrl={wsUrl}
                mixpanelToken={mixpanelToken}
                logError={(msg) => logError(msg)}
                logInfo={(msg) => logInfo(msg)}
              />
            )}
          </WithAuthentication>
        </MsalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
