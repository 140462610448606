import React from 'react';
import { Link } from 'react-router-dom';

import { AppRoute, AssistantProps } from '../../../types';
import { useTranslations } from '../../../utils';
import SearchHistory from './SearchHistory/SearchHistory';
import styles from './DrawerContent.module.scss';

interface Props extends AssistantProps {
  onClose: () => void;
}

const DrawerContent: React.FC<Props> = (props) => {
  const {
    assistant: { drawer: translations },
  } = useTranslations();

  return (
    <div className={styles.container}>
      <div>
        <Link
          to={AppRoute.Home}
          onClick={props.onClose}
          className={styles.newSearchBtn}
          replace
        >
          {translations.newSearch}
        </Link>
        <SearchHistory {...props} />
      </div>
      <div className={styles.tipsContainer}>
        <h3 className={styles.tipsTitle}>{translations.tipsTitle}</h3>
        <p className={styles.tips}>{translations.tipsContent}</p>
      </div>
    </div>
  );
};

export default DrawerContent;
