import React from 'react';
import TableCell from '@mui/material/TableCell';
import clsx from 'clsx';

import { SortDirection, Sorting } from '../../../types';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow-down.svg';
import styles from './TableHeader.module.scss';

interface Props {
  field: string;
  handleColumnClicked: (field: string) => void;
  sorting: Sorting;
  title: string;
}

const TableHeader: React.FC<Props> = ({
  field,
  handleColumnClicked,
  sorting,
  title,
}) => {
  if (sorting === null || sorting.field !== field) {
    return (
      <TableCell onClick={() => handleColumnClicked(field)}>{title}</TableCell>
    );
  }

  return (
    <TableCell
      onClick={() => handleColumnClicked(field)}
      className={clsx(
        styles.sortedHeader,
        sorting.direction === SortDirection.Ascending && styles.ascending,
      )}
    >
      <span>{title}</span>
      <ArrowIcon className={styles.sortIcon} />
    </TableCell>
  );
};

export default TableHeader;
