import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AppRoute, AssistantProps } from '../../../types';
import { useAppDispatch } from '../../../store/utils/hooks';
import { resetInputs } from '../../../store/slices/inputs.slice';
import SmartLoopContainer from '../../SmartLoopContainer/SmartLoopContainer';
import HomeHeader from './Header/HomeHeader';
import HomeOpenJobs from './OpenJobs/HomeOpenJobs';
import { isOpenJobsTableEnabled, useOnDesktop } from '../../../utils';
import { ReactComponent as LinkIcon } from '../../../assets/link.svg';
import styles from './SmartLoopHome.module.scss';

const SmartLoopHome: React.FC<AssistantProps> = (props) => {
  const dispatch = useAppDispatch();
  const showOpenJobsTable = isOpenJobsTableEnabled();
  const onDesktop = useOnDesktop();

  useEffect(() => {
    dispatch(resetInputs());
  }, [dispatch]);

  return (
    <SmartLoopContainer {...props}>
      {onDesktop && (
        <Link to={AppRoute.Surveys} className={styles.curationHubBtn}>
          <span>CurationHub</span>
          <LinkIcon className={styles.linkIcon} />
        </Link>
      )}
      <HomeHeader {...props} />
      {showOpenJobsTable && (
        <div className={styles.openJobsContainer}>
          <HomeOpenJobs {...props} />
        </div>
      )}
    </SmartLoopContainer>
  );
};

export default SmartLoopHome;
