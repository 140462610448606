import React, {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { v1 as generateId } from 'uuid';
import TextField from '@mui/material/TextField';

import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import { askQuestion } from '../../../store/slices/streaming.slice';
import {
  AppRoute,
  AssistantProps,
  MixpanelEventType,
  QuestionSource,
} from '../../../types';
import {
  trackWithMixpanel,
  useLanguage,
  useTranslations,
  useCurrentRoute,
} from '../../../utils';
import { setQuestion } from '../../../store/slices/inputs.slice';

interface Props extends AssistantProps {
  equipmentType: string | null;
  allEquipmentTags: string[] | null;
  conversationId?: string;
}

const SmartLoopQuestionSearch: React.FC<Props> = ({
  wsUrl,
  httpUrl,
  isMocking,
  logInfo,
  logError,
  environment,
  conversationId,
  equipmentType,
  allEquipmentTags,
}) => {
  const dispatch = useAppDispatch();
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { assistant: translations } = useTranslations();
  const { question } = useAppSelector((state) => state.inputs);
  const { copiedRootCauseContent } = useAppSelector((state) => state.analytics);
  const ref = useRef<HTMLInputElement>(null);

  const isMountingRef = useRef(false);
  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key !== 'Enter' ||
      equipmentType === null ||
      allEquipmentTags === null
    ) {
      return;
    }
    const message = question.trim();
    if (message.length < 2) {
      return;
    }
    event.preventDefault();
    const newOrExistingConversationId = conversationId || generateId();
    dispatch(
      askQuestion({
        wsUrl,
        httpUrl,
        isMocking,
        language,
        logInfo,
        logError,
        message,
        equipmentType,
        allEquipmentTags,
        environment,
        conversationId: newOrExistingConversationId,
        source:
          currentRoute === AppRoute.Home
            ? QuestionSource.HomeSearch
            : QuestionSource.FollowUpQuestion,
      }),
    );
    dispatch(setQuestion(''));
    if (currentRoute !== AppRoute.Conversations) {
      navigate(`${AppRoute.Conversations}/${newOrExistingConversationId}`);
    }
  };
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setQuestion(event.target.value));
    },
    [dispatch],
  );

  // We do not want to autofocus when the dashboard is opened for the first time
  useEffect(() => {
    if (!isMountingRef.current || currentRoute === AppRoute.Home) {
      ref.current?.focus();
    }
    isMountingRef.current = false;
  }, [equipmentType]);

  const handlePaste = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      const paste = event.clipboardData.getData('text');
      if (paste === copiedRootCauseContent) {
        trackWithMixpanel({
          environment,
          event: {
            name: MixpanelEventType.RootCausePasted,
            properties: { text: paste },
          },
        });
      }
    },
    [copiedRootCauseContent, environment],
  );

  return (
    <TextField
      fullWidth
      inputRef={ref}
      disabled={equipmentType === null}
      placeholder={
        currentRoute === AppRoute.Home
          ? translations.questionPlaceholder
          : translations.followUpQuestionPlaceholder
      }
      variant="standard"
      InputProps={{ disableUnderline: true }}
      value={question}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
    />
  );
};

export default SmartLoopQuestionSearch;
