import jwtDecode from 'jwt-decode';
import { AccountInfo } from '@azure/msal-common';
import { useEffect, useState } from 'react';

import { isDefined } from './isDefined';
import { JobStatus, OpenJobsResponse } from '../types';

const BASE_URL = process.env.REACT_APP_API_URL;

const CORESYSTEMS_ACCOUNTS = new Set<string>([
  'fsm-data-coresystems',
  'core-rocysa-de',
  'coresystems_D1',
]);

export const AUTH_SESSION_KEY = 'InsightLoopAuth';
export const SHOW_OPEN_JOBS_SESSION_KEY = 'showOpenJobs';

export enum AuthRole {
  ServiceExpert = 'service_expert',
  EndCustomer = 'end_customer',
}

export enum LoginType {
  FSM,
  AZURE,
  USERNAME_PASSWORD,
}

export interface AuthData {
  accountId: string;
  companyId: string;
  identityId: string;
  tenantId: number;
  isInternalAccount: boolean;
  loginType: LoginType;
  token: string;
  expiry: number | null;
  role: AuthRole;
  refreshToken: string;
  cluster?: string | null;
  userId?: string;
}

export interface FSMContext {
  account: string;
  accountId: string;
  company: string;
  companyId: string;
  userId: string;
  selectedLocale: string;
  cloudHost: string;
  auth?: {
    access_token: string;
    token_type: string;
    expires_in: number;
  };
}

export const getAuthDataFromStorage = (): AuthData | null => {
  const session = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (session === null) {
    return null;
  }
  return JSON.parse(session);
};

export const logout = (): void => {
  sessionStorage.clear();
  window.dispatchEvent(new Event('storage'));
};

const AZURE_ACCOUNT_KEY = 'azureAccount';

export const saveAzureAccount = (account: AccountInfo) => {
  sessionStorage.setItem(AZURE_ACCOUNT_KEY, JSON.stringify(account));
};

export const getAzureAccount = (): AccountInfo | null => {
  const account = sessionStorage.getItem(AZURE_ACCOUNT_KEY);

  return account === null ? null : JSON.parse(account);
};

export const useAuthData = (): AuthData | null => {
  const [authData, setAuthData] = useState<AuthData | null>(
    getAuthDataFromStorage,
  );
  useEffect(() => {
    const handleStorage = () => {
      const data = getAuthDataFromStorage();
      setAuthData(data);
    };
    window.addEventListener('storage', handleStorage);

    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, []);

  if (authData === null) {
    return null;
  }
  if (authData.expiry === null) {
    return authData;
  }

  const tokenExpired = authData.expiry * 1000 <= Date.now();
  if (tokenExpired) {
    sessionStorage.clear();
    setAuthData(null);
  }
  return authData;
};

export const useAuthenticated = (): boolean => {
  const authData = useAuthData();

  return authData !== null;
};

export const isTokenAboutToExpire = (): boolean => {
  const authData = getAuthDataFromStorage();
  if (authData === null) {
    return true;
  }
  if (authData.expiry === null) {
    return false;
  }
  const fiveMinutesInMs = 5 * 60 * 1000;
  const expiresAtMs = authData.expiry * 1000;

  return expiresAtMs - fiveMinutesInMs <= Date.now();
};

interface FetchTokenParams {
  token: string;
  isInternalAccount: boolean;
  loginType: LoginType;
  accountId?: string;
  companyId?: string;
  userId?: string;
  cluster?: string | null;
}

interface TokenResponse {
  accountId: string;
  companyId: string;
  identityId: string;
  tenantId: number;
  token: string;
  refreshToken: string;

  data?: {
    identityId: string;
    tenantId: number;
    token: string;
    refreshToken: string;
    accountId: string;
    companyId: string;
  };
}

export const shouldMock = (authData: AuthData | null): boolean => {
  if (authData === null) {
    return false;
  }
  const { isInternalAccount, loginType } = authData;
  const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'production';
  // We do not want to activate mocking for these tenants.
  if (loginType === LoginType.USERNAME_PASSWORD && isProduction) {
    return false;
  }

  return isInternalAccount;
};

const shouldShowOpenJobsTable = async (token: string): Promise<boolean> => {
  let retryCount = 0;
  while (retryCount < 10) {
    try {
      const openJobsResponse: OpenJobsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/fault/api/v1/faults?job_status=${JobStatus.Open}&page=1&size=10`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        },
      ).then((res) => res.json());

      return openJobsResponse.data.meta.totalElements > 0;
    } catch (error) {
      retryCount++;
    }
  }
  console.error('Open Jobs could not be retrieved, disabling component.');

  return false;
};

const fetchToken = async (params: FetchTokenParams): Promise<void> => {
  const response: TokenResponse = await fetch(`${BASE_URL}/auth/api/v1/token`, {
    method: 'POST',
    body: JSON.stringify({
      accountId: params.accountId || '',
      companyId: params.companyId || '',
      token: params.token,
    }),
  }).then((response) => response.json());

  const { token, tenantId, identityId, accountId, companyId, refreshToken } =
    isDefined(response.data) ? response.data : response;
  const isDemoFsmTenant = tenantId === -1;
  const decodedJwt = jwtDecode<{ exp: number; 'custom:role': string }>(token);
  const authData: AuthData = {
    accountId,
    companyId,
    identityId,
    tenantId,
    token,
    refreshToken,
    userId: params.userId,
    cluster: params.cluster,
    loginType: params.loginType,
    isInternalAccount: params.isInternalAccount || isDemoFsmTenant,
    expiry: isDemoFsmTenant ? null : decodedJwt.exp,
    role: decodedJwt['custom:role'] as AuthRole,
  };
  const mock = shouldMock(authData);

  if (mock) {
    sessionStorage.setItem(SHOW_OPEN_JOBS_SESSION_KEY, JSON.stringify(true));
  } else {
    const showOpenJobsTable = await shouldShowOpenJobsTable(token);
    sessionStorage.setItem(
      SHOW_OPEN_JOBS_SESSION_KEY,
      JSON.stringify(showOpenJobsTable),
    );
  }

  sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(authData));
  window.dispatchEvent(new Event('storage'));
};

export const isOpenJobsTableEnabled = (): boolean => {
  const showOpenJobsTable = sessionStorage.getItem(SHOW_OPEN_JOBS_SESSION_KEY);
  if (showOpenJobsTable === null) {
    return true;
  }

  return JSON.parse(showOpenJobsTable);
};

export const fetchRefreshToken = async (): Promise<void> => {
  const authData = getAuthDataFromStorage();
  if (authData === null) {
    throw new Error('No auth data found, cannot refresh token!');
  }
  const {
    accountId,
    companyId,
    isInternalAccount,
    loginType,
    refreshToken,
    token,
  } = authData;

  await fetch(`${BASE_URL}/auth/api/v1/token`, {
    method: 'POST',
    body: JSON.stringify({
      refreshToken,
      token,
      accountId: accountId || '',
      companyId: companyId || '',
    }),
  })
    .then((response) => response.json())
    .then((response: TokenResponse) => {
      const {
        token,
        tenantId,
        identityId,
        accountId,
        companyId,
        refreshToken,
      } = isDefined(response.data) ? response.data : response;
      const isDemoFsmTenant = tenantId === -1;
      const decodedJwt = jwtDecode<{ exp: number; 'custom:role': string }>(
        token,
      );
      const refreshAuthData: AuthData = {
        accountId,
        companyId,
        identityId,
        tenantId,
        token,
        refreshToken,
        loginType,
        isInternalAccount: isInternalAccount || isDemoFsmTenant,
        expiry: isDemoFsmTenant ? null : decodedJwt.exp,
        role: decodedJwt['custom:role'] as AuthRole,
      };
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(refreshAuthData));
      window.dispatchEvent(new Event('storage'));
    });
};

export const loginWithFSM = async (fsmContext: FSMContext) => {
  const { account, accountId, companyId, userId, cloudHost, auth } = fsmContext;
  const cluster = cloudHost.slice(0, 2);
  const isInternalAccount = CORESYSTEMS_ACCOUNTS.has(account);
  if (!isDefined(auth)) {
    throw new Error('FSM Auth data not present!');
  }
  await fetchToken({
    accountId,
    companyId,
    userId,
    cluster,
    isInternalAccount,
    loginType: LoginType.FSM,
    token: auth.access_token,
  });
};

const isInternalUser = (user: string): boolean => {
  return (
    user.match(
      /^.+@(.*coresystems\.ch)|(insightloopprod.onmicrosoft\.com)$/i,
    ) !== null
  );
};

export interface AzureCredentials {
  token: string;
  email: string;
}

export const loginWithAzure = async ({ token, email }: AzureCredentials) => {
  await fetchToken({
    token,
    isInternalAccount: isInternalUser(email),
    loginType: LoginType.AZURE,
  });
};

interface UserCredentials {
  username: string;
  password: string;
}

export const loginWithUsernameAndPassword = async ({
  username,
  password,
}: UserCredentials) => {
  const token = `Basic ${window.btoa(`${username}:${password}`)}`;
  await fetchToken({
    token,
    isInternalAccount: isInternalUser(username),
    loginType: LoginType.USERNAME_PASSWORD,
  });
};

export const getJwtToken = (): string | null => {
  const authData = getAuthDataFromStorage();

  return authData === null ? null : authData.token;
};

export const getUserType = (): AuthRole | null => {
  const authData = getAuthDataFromStorage();
  if (authData === null) {
    throw new Error('User not logged in!');
  }

  return authData.role;
};

export const isUserServiceExpert = (): boolean => {
  const userType = getUserType();
  if (!isDefined(userType)) {
    return true;
  } else {
    return userType === AuthRole.ServiceExpert;
  }
};
