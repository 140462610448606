import { SortDirection, Sorting } from '../types';

export const getSortingFromColumn = (
  field: string,
  previousSorting: Sorting,
): Sorting => {
  if (previousSorting.field !== field) {
    return {
      field,
      direction: SortDirection.Ascending,
    };
  } else {
    return {
      field,
      direction:
        previousSorting.direction === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending,
    };
  }
};
