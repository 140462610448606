import React from 'react';
import clsx from 'clsx';

import { useTranslations } from '../../../../../../../utils';
import styles from './TroubleshootingStartedTooltip.module.scss';

const TroubleshootingStartedTooltip: React.FC = () => {
  const {
    assistant: { troubleshooting: translations },
  } = useTranslations();

  return (
    <>
      <p className={styles.summary}>{translations.tooltipSummary}</p>
      <ul className={styles.sourceList}>
        <li className={clsx(styles.source, styles.document)}>
          {translations.tooltipDocumentSource}
        </li>
        <li className={clsx(styles.source, styles.serviceCase)}>
          {translations.tooltipServiceCaseSource}
        </li>
      </ul>
    </>
  );
};

export default TroubleshootingStartedTooltip;
